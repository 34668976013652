<template>
  <header class="bg-bgr container-fluid-lg">
    <div
      class="flex justify-between items-center py-4 lg:container print:max-w-sm"
    >
      <WebccCompanyLogo
        :caption="logoCaption"
        :href="logoHref"
        external
        target="_self"
      />

      <ClientOnly>
        <HeaderSelect
          v-if="sites?.length && sites?.length > 1"
          :selected-value="language!"
          :all-options="sites!"
          :has-key="true"
          object-key="language"
          :to-upper-case="true"
          @on-change="changeLanguage"
        />
      </ClientOnly>
    </div>
  </header>
</template>

<script lang="ts" setup>
const tracking = useTracking()

interface Props {
  logoUrl?: string
}

const props = withDefaults(defineProps<Props>(), { logoUrl: '/' })
const { language, sites, logoCaption } = storeToRefs(useConfdata())

const logoHref: ComputedRef<string> = computed(() =>
  getHomeNavigation(
    props.logoUrl,
    useParams().removeQueryParams(useRoute().query, [
      'code',
      'flow',
      'email',
      'returnTo',
    ]),
  ),
)

// Change the selected language
function changeLanguage(language: string) {
  tracking.handlers?.header.languageSwitch(language)

  // Construct the new URL
  const url = useRequestURL()

  // Set the language parameter
  url.searchParams.set('language', language)
  window.location.href = url.href
}
</script>
