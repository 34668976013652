<template>
  <div class="flex min-h-screen flex-col text-txt-strongest">
    <Colors :colors="colors" />
    <HeaderLogin :logo-caption="logoCaption!" />
    <span class="h-px bg-bgr-300"></span>
    <slot />
  </div>
</template>

<script lang="ts" setup>
const { logoCaption } = useConfdata()
const { colors } = useColors()
</script>

<style>
.login-page-enter-active,
.login-page-leave-active {
  transition: all 0.3s ease-in-out;
}

.login-page-enter-from,
.login-page-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
